import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/veterans.scss"
import ImageSlider from "../components/ImageSlider/ImageSlider"

const SmileForVeteransPage = () => {
  return (
    <Layout language="en">
      <SEO
        title="Smiles For Veterans Program"
        description="Smiles For Veterans Program at Canyon Oral & Facial Surgery "
      />
      <div className="sfv">
        <div className="sfv__hero sfv__section">
          <img
            className="sfv__hero-img--desktop"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smiles-for-veterans-desktop-hero-image"
            alt="smiles for soliders banner"
          />
          <img
            className="sfv__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smiles-for-veterans-mobile-hero-image"
            alt="smiles for soliders banner"
          />
        </div>

        <div className="sfv__body sfv__section">
          <div className="sfv__container small">
            <h1>SUPPORTING THOSE WHO SERVE</h1>

            <p>
              The team at Canyon Oral & Facial Surgery is proud to announce that
              this year’s Smiles For Veterans recipients are Neal and Carlos
              from Las Vegas, Nevada!
            </p>
            <p>
              Carlos, 41, is a retired Army veteran who served on the front
              lines in two combat zones. After being diagnosed with PTSD, Carlos
              spent years struggling with the weight of his experiences, often
              battling feelings of guilt and anger. In his application, Carlos
              shared that his broken and damaged teeth have caused constant pain
              and bleeding, made it difficult to eat, and affected his
              self-esteem. He expressed hope that restoring his smile would not
              only improve his physical health but also help him feel better
              about himself on the inside, as he continues to work through the
              challenges of PTSD. Carlos feels that this opportunity would allow
              him to rebuild his confidence and face the world with a renewed
              sense of pride.
            </p>
            <p>
              Neal, 34, is an Army veteran who now works as a delivery driver.
              In 2009, he was involved in a training accident that led to a
              below-the-knee amputation. Despite being told he would never walk
              or talk again, Neal defied the odds and proved the doctors wrong.
              Today, Neal is facing another challenge: his dental health. He
              shared that his teeth are uncomfortable, with one sticking out
              more than it should, and his gums bleed. Once someone who smiled
              freely, Neal now hides his teeth when talking to others. He hopes
              that restoring his smile will help him regain his confidence and
              stop feeling like he’s hiding a part of himself.
            </p>
            <p>
              We hope that providing Carlos and Neal with the smile restoration
              they deserve will not only improve their oral health but also give
              them the confidence to smile freely once again.
            </p>

            <h2 style={{ fontSize: "24px" }}>
              All-on-4<sup>®</sup> Treatment Concept
            </h2>
            <p>
              Both Carlos and Neal will receive a full set of customized,
              artificial teeth at no cost. This tooth replacement solution is
              called full-arch restoration because it replaces a full dental
              arch of missing or failing teeth. Our team of experts will work
              closely with a restorative dentist to complete the treatment.
            </p>
          </div>
        </div>

        {/* <div className="sfv__section">
          <div className="sfv__container">
            <ImageSlider
              adaptiveHeight
              useDots
              images={[
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-1",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-2",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-3",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-4"
              ]}
            />
          </div>
        </div> */}

        <div className="sfv__section">
          <div className="sfv__container small">
            <p>
              Follow this page,{" "}
              <a
                href="https://www.facebook.com/Canyonofs.CentennialHills/"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>
              , and{" "}
              <a
                href="https://www.instagram.com/canyonofs/"
                title="Follow us on Instagram"
                target="_blank">
                Instagram
              </a>{" "}
              to watch Carlos and Neal’s journeys to a new smile!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/oral-surgery-procedures/dental-implants-las-vegas-nv/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/oral-surgery-procedures/full-arch-restoration-all-on-4-las-vegas-nv/"
                  title="Learn more about All-on-4">
                  All-on-4
                </Link>{" "}
                to see how they can improve your oral health and confidence.
                Whether you need to replace one tooth or an entire arch of
                teeth, our team has a solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SmileForVeteransPage
